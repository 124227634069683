import moment from "moment";
import { Badge, Col, Row } from "react-bootstrap";
import { Blob_Base_Url, staticImages } from "../../utils/static";
import { SubmitButton } from "./input";
import Link from "next/link";
import { IKImage } from "imagekitio-react";
const status = [
	{
		title: "Pending",
		bg: "warning",
		text: "dark",
	},
	{
		title: "Active",
		bg: "primary",
		text: "white",
	},
	{
		title: "Disable",
		bg: "danger",
		text: "white",
	},
	{
		title: "Completed",
		bg: "success",
		text: "white",
	},
	{
		title: "Deleted",
		bg: "danger",
		text: "white",
	},
	{
		title: "Banned",
		bg: "danger",
		text: "white",
	},
	{
		title: "Cancelled",
		bg: "danger",
		text: "white",
	},
];

export const BadgeStatus = ({ data, light, ...props }) => {
	const item = status.find((s) => s.title === data);
	if (!item) return <span className="fw-600 text-blue">{data}</span>;
	if (light) item.text = item.bg;
	return (
		<Badge {...item} className={`px-2 rounded-pill py-0 font-sm`} style={{ lineHeight: "22px", height: "22px" }} {...props}>
			{item.title}
		</Badge>
	);
};

export const NoItemFound = ({ title = "Oops! ..", subtitle, children, text, href = "#", img = "empty.png", ...props }) => (
	<Row className="align-items-center pt-3 pb-5 justify-content-between">
		<Col sm="4" className="text-center">
			<p className="fw-bold fs-5">{title}</p>
			<p>{subtitle}</p>
			<Link href={href}>
				<SubmitButton variant="dark shadow-none" {...props} title={text} />
			</Link>
		</Col>
		<Col sm="6">
			<img className="w-100" src={Blob_Base_Url + staticImages.noResult} />
		</Col>
	</Row>
);

export const ShortDate = ({ data, ...props }) => (
	<p className="mb-0 text-danger font-md" {...props}>
		{moment(data).format("MMM Do YY")}
	</p>
);

export const Line = ({ padding = "1", color = "" }) => <p className={`y-${padding} w-100 border-bottom border-${color} rounded-pill`} />;

export const Feature = ({ children, className }) => <div className={`bg-light mb-3 d-flex align-items-center font-lg rounded-8 px-3 py-2 ${className}`}>{children}</div>;

export const Logo = ({ height = 40, width = 120, className }) => (
	<img
		style={{
			height,
		}}
		src={"/logo.png"}
		className={className}
	/>
);

export const ImageKitImage = ({ url, bg = "white", height, width, src, ...props }) => (
	<IKImage
		transformation={[
			{
				height: height,
				width: width,
				bg,
				cropMode: "pad_resize",
			},
		]}
		urlEndpoint={Blob_Base_Url}
		path={src}
		{...props}
	/>
);

export const DotLoading = () => (
	<div className="lds-ellipsis">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
);
